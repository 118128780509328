export function parseDate(date) {
  let dateArr = date.split('/').reverse();
  return new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
}

export function allowedStatus2change(statusChoices, isModerate, userStatus, isOwnFeature, currentRouteName) {
  if ( //* si admin, modérateur ou super contributeur, statuts toujours disponibles: Brouillon, Publié, Archivé
    userStatus === 'Modérateur' ||
      userStatus === 'Administrateur projet' ||
      (userStatus === 'Super Contributeur' && !isModerate)
  ) {
    return statusChoices.filter((el) => el.value !== 'pending');
  } else if (userStatus === 'Super Contributeur' && isModerate) {
    return statusChoices.filter(
      (el) => el.value === 'draft' || el.value === 'pending'
    );
  } else if (userStatus === 'Contributeur') { //* cas particuliers du contributeur
    if (currentRouteName === 'ajouter-signalement' || !isOwnFeature) {
      //* même cas à l'ajout d'une feature ou si feature n'a pas été créé par le contributeur
      return isModerate
        ? statusChoices.filter(
          (el) => el.value === 'draft' || el.value === 'pending'
        )
        : statusChoices.filter(
          (el) => el.value === 'draft' || el.value === 'published'
        );
    } else {
      //* à l'édition d'une feature et si le contributeur est l'auteur de la feature
      return isModerate
        ? statusChoices.filter(
          (el) => el.value !== 'published' //* toutes sauf "Publié"
        )
        : statusChoices.filter(
          (el) => el.value !== 'pending' //* toutes sauf "En cours de publication"
        );
    }
  }
  return [];
}